<template>
    <Guest>
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 class="mt-6 text-center text-3xl leading-9 font-extrabold text-white uppercase">
                <i18n-t keypath="login.forgot.title" />
            </h2>
        </div>
        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <p class="pb-4">
                    <i18n-t keypath="login.forgot.description" />
                </p>
                <form v-on:submit.prevent="handleSubmit">
                    <TextInput v-model:value="form.email" v-model:errors="errors.email" :label="$t('login.forgot.email.title')" :placeholder="$t('login.forgot.email.placeholder')" border="enable" />
                </form>
            </div>         
            <div class="mt-6 grid grid-cols-2 gap-3">   
                <router-link :to="{ name: 'login' }">
                    <button type="button" class="button-transparent w-full">
                        <i18n-t keypath="general.back" />
                    </button>
                </router-link>    
                <button @click="handleSubmit" type="button" class="button w-full">
                    <i18n-t keypath="login.forgot.submit" />
                    <!-- <svg v-if="!isLoading" class="absolute right-0 animate-spin w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 12h.01M12 12h.01M16 12h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg> -->
                </button>
            </div> 
        </div>
    </Guest>
</template>

<script>
import Guest from '@/wrappers/Guest';
import TextInput from "@/components/forms/TextInput";
import { requestEmail } from "@/helper/api/PasswordResetHelper";
import messages from "@/helper/store/messageHelper";
import { setLoading } from "@/helper/store/loadingSpinnerHelper";
import i18n from "@/i18n";
const $t = i18n.global.t;

export default {
    components: { 
        Guest, 
        TextInput,
    },
    data() {
        return {
            form: {
                email: '',
            },
            errors: {
                email: [],
            },
        }
    },
    methods: {
        handleSubmit(){
            setLoading(true);

            requestEmail(this.form)
                .then(() => {
                    messages.success($t('login.forgot.success'));
                })
                .catch(err => {
                    messages.error($t('login.forgot.failure'));
                    this.errors = err.errors;
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    },
}
</script>